import { toast } from "react-toastify";
import { useState, useEffect } from 'react';

const Input = ({
  type,
  label,
  placeholder,
  changeHandler,
  blurHandler,
  value,
  className,
  name
}) => {


  const handleShowInputChange = (e) => {
    // Remove non-numeric characters, except for empty string
    const newValue = e.target.value.replace(/[^0-9]/g, '');
    e.target.value = newValue;
  };

  const handleShowInputChangeOther = (e) => {
    // Check if the first character is a space
    if (e.target.value.length === 1 && e.target.value[0] === ' ') {
      // If the first character is a space, set the input value to an empty string
      e.target.value = '';
      return;
    }
  };

  return (
    <>
    <div className={`w-full ${className ? className : ''}`}>
      
      <div className="form-group row">
        <label className='d-block text-dark fs-small fw-bold col-sm-4'>{label}</label>
        <div className="col-sm-8">

        {
            name === 'requestedPlanReviewType' ?
              <textarea
                className="form-control p-2 bg-white rounded border border-gray-300 shadow-sm focus-border-indigo focus-ring-indigo small-text"
                type={`${type ? type : 'text'}`}
                name={name}
                placeholder={placeholder}
                onChange={changeHandler}
                onBlur={blurHandler}
                onInput={handleShowInputChangeOther}
                value={value}
              />
            :
            name === 'contactPhoneNumber' ?
            <>
              <input
                className="form-control p-2 bg-white rounded border border-gray-300 shadow-sm focus-border-indigo focus-ring-indigo small-text"
                type={`text`}
                name={name}
                placeholder={placeholder}
                onChange={changeHandler}
                onBlur={blurHandler}
                min="8"
                maxLength={'15'}
                inputmode="numeric"
                pattern="[0-9]*"
                onInput={handleShowInputChange}
                value={value}
                title="Non-negative integral number"
              />
              </>
            :
              <input
                className="form-control p-2 bg-white rounded border border-gray-300 shadow-sm focus-border-indigo focus-ring-indigo small-text"
                type={`${type ? type : 'text'}`}
                name={name}
                placeholder={placeholder}
                onChange={changeHandler}
                onBlur={blurHandler}
                onInput={handleShowInputChangeOther}
                value={value}
              />
          }
        
        {/* {name === 'requestedPlanReviewType' ?      
            <textarea
            className="form-control p-2 bg-white rounded border border-gray-300 shadow-sm focus-border-indigo focus-ring-indigo small-text"
            type={`${type ? type : 'text'}`}
            name={name}
            placeholder={placeholder}
            onChange={changeHandler}
            onBlur={blurHandler}
            value={value}></textarea> :
          <input
            className="form-control p-2 bg-white rounded border border-gray-300 shadow-sm focus-border-indigo focus-ring-indigo small-text"
            type={`${type ? type : 'text'}`}
            name={name}
            placeholder={placeholder}
            onChange={changeHandler}
            onBlur={blurHandler}
            value={value}
          />
        } */}
        </div>
      </div>
    </div>
    </>
  );
};

export default Input;
