const Footer = () => {
  return (
    <div className="bg-white w-100 fixed-bottom">
    <div className="d-flex align-items-center small p-2 justify-content-between">
      <div>
        <p className="text-center mb-0">
          &copy; {new Date().getFullYear()} All Rights Reserved
        </p>
      </div>
      <div className="d-flex gap-8 gap-lg align-items-center">
        {/* <small>Privacy Policy</small>
        <small>About Us</small> */}
      </div>
    </div>
  </div>
  
  );
};

export default Footer;
