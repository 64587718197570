import './input.css';
import Header from './Components/Headers/Header';
import Footer from './Components/Footer/Footer';
import { Link, useParams  } from 'react-router-dom';
import { useState, useEffect, useRef  } from 'react';
import Input from './Components/Input';
import { ToastContainer, toast } from 'react-toastify';
import { loadStripe } from "@stripe/stripe-js";
import { CardElement, useStripe, Elements  } from '@stripe/react-stripe-js';
import 'react-toastify/dist/ReactToastify.css';
import PlaceComponent from './Components/addressAutoPop';
import { Document, Page, pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
import leftImg from './Assets/images/left-chevron.png';
import rightImg from './Assets/images/right-icon.png';
import closeImg from './Assets/images/close.png';
import { ColorRing } from 'react-loader-spinner';
import PayMentGateway from './Components/PayMentGateway';
import InitialPaymentGateway from './Components/InitialPaymentGateway';
const stripePromise = loadStripe('pk_test_51OaK4THXr2zVmhMlYlLB4xiS33P32eMmoSSn3T3tzgoYE5aA58bmwzcL4Fu9kCNRau6jfEVOJ41OUwVxleSkNVMN00pYAdXCcU');

function App() {
  const [screen, setScreen] = useState(1);
  const [showPdfPopup, setShowPdfPopup] = useState(false);
  const [popupPdfUrl, setPopupPdfUrl] = useState('');
  const [disableNextButton, setDisableNextButton] = useState([]);
  const [pdfContainers, setPdfContainers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [comments , setComment] = useState('');
  const [values, setValues] = useState({
    contactPhoneNumber: '',
    address: '',
    requestedPlanReviewType: '',
    name: '',
    email: '',
    communitiesEmailAddress: '',
  });

  const [userOccupation, setUserOccupationDetails] = useState([]);

  const [isChecked, setIsChecked] = useState('pdfupload');

  const OnchnagePdfShow = (event) => {
    setIsChecked(event.target.value); // 
    if (event.target.value === 'pdfupload') {
      setPdfUrl('');
      setPreviewUrl('');
    }else {
      setSelectedFiles([]);
    }
  }; 



  const [userType, setUserType] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [showModalInitial, setShowModalInitial] = useState(false);





  const [selectedFiles, setSelectedFiles] = useState([]);
  const [numPages, setNumPages] = useState([]);
  const [pageNumbers, setPageNumbers] = useState([]);
  const [disablePrevButton, setDisablePrevButton] = useState([]);
  const [pdfileData  ,setPdfFile] = useState([]);

  const onFileChange = (e) => {
    const files = e.target.files;
    const selectedFiles = Array.from(files);
  
    setPdfFile((prevFiles) => [...prevFiles, ...selectedFiles]);
    setSelectedFiles((prevSelectedFiles) => [...prevSelectedFiles, ...selectedFiles]);
    setNumPages((prevNumPages) => [...prevNumPages, ...Array(selectedFiles.length).fill(1)]);
    setPageNumbers((prevPageNumbers) => [...prevPageNumbers, ...Array(selectedFiles.length).fill(1)]);
    setDisablePrevButton((prevDisablePrevButton) => [...prevDisablePrevButton, ...Array(selectedFiles.length).fill(true)]);
    setDisableNextButton((prevDisableNextButton) => [...prevDisableNextButton, ...Array(selectedFiles.length).fill(false)]);
  };
  
  
  
  console.log("Selected File__________", selectedFiles);


  // Pdf File 

  const [pdfUrl, setPdfUrl] = useState('');
  const [previewUrl, setPreviewUrl] = useState('');

  console.log("Pdf_File Data......", pdfileData);

  // useEffect(() => {
  //   setPreviewUrl(pdfUrl);
  // }, [pdfUrl]);

  const handleInputChangePdf = (event) => {
    const url = event.target.value;
    setPdfUrl(url);

    // Validate if the entered URL is a PDF URL
    const hasHttpProtocol = /^(https?|ftp):\/\//i.test(url);
    setPreviewUrl(hasHttpProtocol ? url : '');
  };


  console.log("Pdf Url Data____", pdfUrl);


  // Pdf File End 

  const handlePageChange = (fileIndex, newPageNumber) => {
    const updatedPageNumbers = [...pageNumbers];
    updatedPageNumbers[fileIndex] = newPageNumber;
    setPageNumbers(updatedPageNumbers);
    const updatedPrevButton = [...disablePrevButton];
    const updatedNextButton = [...disableNextButton];
    updatedPrevButton[fileIndex] = newPageNumber === 1;
    updatedNextButton[fileIndex] = newPageNumber === numPages[fileIndex];
    setDisablePrevButton(updatedPrevButton);
    setDisableNextButton(updatedNextButton);
  };


  const openPdfPopup = (fileIndex) => {
    const file = selectedFiles[fileIndex];
    const url = URL.createObjectURL(file);
    setPopupPdfUrl(url);
    setShowPdfPopup(true);
  };

  const removePdf = (index) => {
    setSelectedFiles(prevFiles => [...prevFiles.slice(0, index), ...prevFiles.slice(index + 1)]);
    const updatedNumPages = [...numPages];
    updatedNumPages.splice(index, 1);
    setNumPages(updatedNumPages);
    const updatedPageNumbers = [...pageNumbers];
    updatedPageNumbers.splice(index, 1);
    setPageNumbers(updatedPageNumbers);
    const updatedContainers = [...pdfContainers];
    updatedContainers.splice(index, 1);
    setPdfContainers(updatedContainers);
    const updatedPrevButton = [...disablePrevButton];
    const updatedNextButton = [...disableNextButton];
    updatedPrevButton.splice(index, 1);
    updatedNextButton.splice(index, 1);
    setDisablePrevButton(updatedPrevButton);
    setDisableNextButton(updatedNextButton);
  };


  const [payementid,setPayemtId] = useState(null);
  const [paymentData , setPaymentData] = useState('');
  useEffect(() => {
    const currentUrl = window.location.href;
    const urlParts = currentUrl.split('/');
    const id = urlParts[urlParts.length - 1];
    setPayemtId(id);
  }, []);

  const [partialPayments ,setPartialPayments] = useState();
  const [partialPaymentsdata ,setPartialPaymentsData] = useState([]);

  console.log(partialPaymentsdata, "partialPaymentsdata___________")

  useEffect(() => {
    const url = window.location.href;
    const urlObject = new URL(url);
    const pathnameParts = urlObject.pathname.split("/");
    const partial_payments = pathnameParts[2];
    const id = pathnameParts[3];
    console.log("partial_payments:____", partial_payments); 
    
    setPartialPayments(partial_payments);
    
    // Output: partial_payments
    console.log("id:", id); // Output: 1

  },[]);

  const UsePayMentPage = async (id) => { 

    setIsLoading(true);

    if (partialPayments === 'current_payments') {
      setIsLoading(true);
      try {
        const resultPartialPayment = await fetch(`https://api.simplecheck.io/api/v1/user/get_partial_payment_details_by_id/${id}`, {
          method: 'GET',
          headers: {
            'x-api-key': `planandreviewdd52ea2d-4567-4956-9d19-35a7e75a2c17`,
            platform: 'web',
          }
        }); 
        const responsePartialPayment = await resultPartialPayment.json();
        console.log("responsePartialPayment", responsePartialPayment)

        if(responsePartialPayment.status){          
          setPartialPaymentsData(responsePartialPayment?.data);
          setIsLoading(false);
        }else{
          setIsLoading(false);
        }
      }catch (error) {
        toast.error(error);
        setIsLoading(false);
      }
      
    }else{
    
    setIsLoading(true);
    try {
      const result = await fetch(`https://api.simplecheck.io/api/v1/user/get_approved_project_details/${id}`, {
        method: 'POST',
        headers: {
          'x-api-key': `planandreviewdd52ea2d-4567-4956-9d19-35a7e75a2c17`,
          platform: 'web',
        }
      }); 
      const response = await result.json();
      
      if(response.status === true){
        setPaymentData(response?.data);
        setIsLoading(false);
      }else{
        setIsLoading(false);
      }
    } catch (error) {
      toast.error(error);
      setIsLoading(false);
    }
  }


 }

 console.log(payementid, "_________________________payementid");

 useEffect(() => {
  UsePayMentPage(payementid);
 },[payementid]);

  const renderPdfPreviews = () => {
    if (selectedFiles.length === 0) {
      return <div>No PDF Selected</div>;
    }

    return selectedFiles.map((file, index) => (
      <div className='pdf_viewFile' key={index}>
        {/* <h2>Pdf File {index + 1}</h2> */}
        <div className='openinBox' onClick={() => openPdfPopup(index)}>
          <Document file={file}  onLoadSuccess={({ numPages }) => setNumPages((prevNumPages) => [...prevNumPages.slice(0, index), numPages, ...prevNumPages.slice(index + 1)])}>
            <Page pageNumber={pageNumbers[index]} />
          </Document>
        </div>
       <div className='mainClsmn'>
        <small className='Incls'>
          Page {pageNumbers[index]} of {numPages[index]}
        </small>
        <div className='PsAmn'>
          <button onClick={() => handlePageChange(index, pageNumbers[index] - 1)} disabled={disablePrevButton[index]}> <img src={leftImg} /> </button>
          <button onClick={() => handlePageChange(index, pageNumbers[index] + 1)} disabled={disableNextButton[index]}><img src={rightImg} /></button>
        </div>
        </div>
        <p className='NameFile'>{file.name}</p>
        <button className='removePdf' onClick={() => removePdf(index)}> <img src={closeImg} alt='Close' /> </button>
      </div>
    ));
  };

  const screenChanger = () => {
    screen > 1 && setScreen(1);
    window.location.reload();
  };

  const handleUserType = (value) => {
    setUserType(value);
    setScreen(2);
  };

  const closePdfPopup = () => {
    setShowPdfPopup(false);
    setPopupPdfUrl('');
  };

  const [toastId, setToastId] = useState(null);


  const welcomeInputHandler = () => {
    
    setIsChecked('pdfupload');
    setPdfUrl('');
    setSelectedFiles([]);
    setPreviewUrl('');

  let errorMessage = '';
  if (values.address === '') {
    errorMessage = 'Please enter an address';
  } else if (values.name === '') {
    errorMessage = 'Please enter a name';
  } else if (values.contactPhoneNumber === '') {
    errorMessage = 'Please enter a phone number';
  } else if (values.contactPhoneNumber.length < 8) {
    errorMessage = 'Please enter a valid phone number';
  } else if (values.contactPhoneNumber.length > 15) {
    errorMessage = 'Your phone number is greater than 15 character';
  } else if (values.email === '') {
    errorMessage = 'Please enter an email address';
  }else if (!validateEmail(values.email)) {
    errorMessage = 'Please enter a valid email address';
  }else if (values.requestedPlanReviewType === '') {
    errorMessage = 'Please enter a plan review type';
  }
  if (errorMessage !== '' && !toast.isActive(toastId)) {
    const newToastId = toast.error(errorMessage);
    setToastId(newToastId);
  } else if (errorMessage === '') {
    setScreen(3);
    // Reset the toastId so that the next toast can be displayed
    setToastId(null);
  }
  setIsLoading(false);
  };

  // Function to validate an email address
function validateEmail(email) {
  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  return emailRegex.test(email);
}

// Function to validate a phone number (simple example, you can adjust it based on your requirements)
function validatePhoneNumber(phoneNumber) {
  // Check if phoneNumber is defined and is a string
  if (typeof phoneNumber === 'string') {
    // Remove all non-numeric characters from the input and check if it's a 10-digit number
    const numericPhoneNumber = phoneNumber.replace(/\D/g, ''); // Remove non-numeric characters
    return numericPhoneNumber.length  && !isNaN(parseInt(numericPhoneNumber));
  }
  return false; // Return false if phoneNumber is not a string
}



  console.log("selectedFile Dataaaaa", pdfUrl);

  const WelcomeBackBtn = async () => {
    window.location.reload();
  }

  const WelcomeBackBtnProject = async () => {
    window.location.reload();
  }

    const [showSuccessMessage, setShowSuccessMessage] = useState(false);

      useEffect(() => {
        if (showSuccessMessage) {
          toast.success("Project Information Submitted Successfully");
        }
      }, [showSuccessMessage]);

  
      const [toastIdSec, setToastIdSecond] = useState(null);
      const textareaRef = useRef(null);


  const submitHandler = async () => {

    const textareaValue = textareaRef.current ? textareaRef.current.value : '';


    let errorMessageSecond = '';
    if (!textareaValue.trim()) {
      errorMessageSecond = 'Please enter a comments';
    } else if (selectedFiles.length === 0 && (pdfUrl === undefined || pdfUrl === '')) {
      errorMessageSecond = 'Please Upload PDF Or Paste Your PDF URL';
    } 
    if (errorMessageSecond !== '' && !toast.isActive(toastIdSec)) {
      const newToastIdSec = toast.error(errorMessageSecond);
      setToastIdSecond(newToastIdSec);
    } else if (errorMessageSecond === '') {
      // Reset the toastId so that the next toast can be displayed
      
    
      setToastIdSecond(null);
      const formData = new FormData();
      Object.keys(values).forEach((key) => {
        formData.append(key, values[key]);
      });

      if (textareaRef.current) {
        const textareaValue = textareaRef.current.value;
        formData.append('comments', textareaValue);
      }
      
      

      formData.append('userType', userType);

      if (pdfileData.length === 0) {
        formData.append('externalFiles', pdfUrl);
      }else {
        pdfileData?.map((item) => formData.append("file", item));
      }

      console.log(formData);
      
      setIsLoading(true);

    
      const result = await fetch('https://api.simplecheck.io/api/v1/user/add_user_project_and_files', {
        method: 'POST',
        headers: {
          'x-api-key': `planandreviewdd52ea2d-4567-4956-9d19-35a7e75a2c17`,
          platform: 'web',
        },
        body: formData,
      });

      const response = await result.json();
      console.log("Response Dataaaaaaaaaa",response);

      if (response.status) {
        toast.success(response.message);
        // setShowSuccessMessage(true);
        setIsLoading(true);
        // setScreen(1);
        setTimeout(() => {
          setIsLoading(true);  
          window.location.reload();
        }, 400);
      }else{
        toast.success(response.message);
      }

      setToastIdSecond(null);

    }
  };

  const inputList = [
    {
      type: 'text',
      label: 'Address*',
      placeholder: 'Enter Your Address',
      name: 'address',
      className: 'pt-2',
    },
    {
      type: 'text',
      label: 'Your Name*',
      placeholder: 'Enter Your Name',
      className: 'pt-2',
      name: 'name',
    },
    {
      type: 'number',
      label: 'Contact Phone Number *',
      placeholder: 'Enter Contact Phone Number',
      className: 'pt-2',
      name: 'contactPhoneNumber',
    },
    {
      type: 'email',
      label: 'Your Email Address*',
      placeholder: 'Enter Your Email Address',
      className: 'pt-2',
      name: 'email',
    },
    {
      type: 'text',
      label: 'Your Communities Email Address',
      placeholder: 'Enter Your Communities Email Address',
      className: 'pt-2',
      name: 'communitiesEmailAddress',
    },
    {
      type: 'text',
      label: 'Requested Plan Review Type*',
      placeholder: 'Enter Requested Plan Review Type',
      className: 'pt-2',
      name: 'requestedPlanReviewType',
    },

  ];
  const handleInputChange = (e) => {
    const name = e.target.name;
    const value =
      e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    setValues({
      ...values,
      [name]: value,
    });
    
  };
  





 console.log("Commentts Dataaaaaaaaaaa", comments);

    const handleShowInputChangeOther = (e) => {
    // Check if the first character is a space
    if (e.target.value.length === 1 && e.target.value[0] === ' ') {
      // If the first character is a space, set the input value to an empty string
      e.target.value = '';
      return;
    }

  };
  

 const UsetyPeListing = async () => { 
    try {
      const result = await fetch('https://api.simplecheck.io/api/v1/user/inspection_types', {
        method: 'GET',
        headers: {
          'x-api-key': `planandreviewdd52ea2d-4567-4956-9d19-35a7e75a2c17`,
          platform: 'web',
        }
      }); 
      const response = await result.json();
      setUserOccupationDetails(response?.data);
    } catch (error) {
      toast.error(error);
    }
 }

 useEffect(() => {
  UsetyPeListing();
 },[]);

  const Usertype = (
    <div className='d-flex justify-content-center align-items-center  md-vh-0 p-4'>
      <div>
        <h1 className='py-3 font-weight-bold h4'>Select Plan Type</h1>
        <div>
          <div className='row row-cols-2 g-4 mx-auto max-wdthdata dtaMnSwn'>
            {userOccupation?.map((user, i) => {
              return (
                <button key={i} className='bg-white rounded-lg shadow-lg' onClick={() => handleUserType(user.inspectionTypes)}>
                    <div className='d-flex justify-content-center imgHgtWdth'>
                      <img
                        className="rounded-lg img-fluid WdthPostmn"
                        src={user?.url}
                        alt={user?.inspectionTypes}
                      />
                    </div>
                    <h1 className=' p-3 text-center h5  mb-0'>
                      {user?.inspectionTypes}
                    </h1>
                </button>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );

  const PayementPage = (
    <div className='d-flex justify-content-center align-items-center  md-vh-0 p-4'>

    {isLoading ? (
          <div className='LodingLoader'>
              <ColorRing
                visible={true}
                height="140"
                width="140"
                ariaLabel="blocks-loading"
                wrapperStyle={{}}
                wrapperClass="blocks-wrapper"
                colors={['#6366f1', '#6366f1', '#6366f1', '#6366f1', '#6366f1']}
              />
          </div>
      ) : (
        <>   

        {partialPayments === 'current_payments' ? <> <div>
        <h1 className='py-3 font-weight-bold h4 text-center'> Current Payments</h1>
         <div className='DetailsChanges'>
             
             <div className='d-flex brder relative'>
                 <div className='textCnme'> 
                   Name : 
                 </div>
                 <div className='textCnme'> 
                    {partialPaymentsdata?.name}
                 </div>
             </div>

             <div className='d-flex brder relative'>
                 <div className='textCnme'> 
                   Email : 
                 </div>
                 <div className='textCnme'> 
                   {partialPaymentsdata?.email}
                 </div>
             </div>

             <div className='d-flex brder relative'>
                 <div className='textCnme'> 
                   Plan Type : 
                 </div>
                 <div className='textCnme'> 
                   {partialPaymentsdata?.userType}
                 </div>
             </div>

             {/* <div className='d-flex brder relative'>
                 <div className='textCnme'> 
                 Total Amount : 
                 </div>
                 <div className='textCnme'> 
                   ${partialPaymentsdata?.totalAmount}
                 </div>
             </div> */}

             <div className='d-flex brder relative'>
                 <div className='textCnme'> 
                  Current Amount : 
                 </div>
                 <div className='textCnme'> 
                   ${partialPaymentsdata?.partialAmount ?? 0}
                 </div>
             </div>

             {/* <div className='d-flex brder relative'>
                 <div className='textCnme'> 
                 Balance Amount : 
                 </div>
                 <div className='textCnme'> 
                   ${partialPaymentsdata?.balanceAmount}
                 </div>
             </div> */}

             <div className='d-flex brder relative'>
                 <div className='textCnme'> 
                  Payment Status : 
                 </div>
                 <div className='textCnme'> 
                   {partialPaymentsdata?.paymentStatus}
                 </div>
             </div>

             <div className='d-flex brder relative'>
                 <div className='textCnme'> 
                   Address : 
                 </div>
                 <div className='textCnme'> 
                   {partialPaymentsdata?.address}
                 </div>
             </div>

             <div className='PayNowBtn'>

              {partialPaymentsdata?.paymentStatus == 'Successful' ? <> <p 
                  className='text-center  wdthshw   cursor-not-allowed py-2 mt-4 rounded-lg bg-green-500 text-white'
                >
                  Paid
              </p> </> : <>  <button type="button"
                  onClick={() => setShowModalInitial(true)}
                  className='text-center  wdthshw  py-2 mt-4 rounded-lg bg-indigo-500 text-white'
                >
                  Pay Now
              </button> </>  }
              
             </div>

            {showModalInitial ? (
                <>
                  <div
                    className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                  >
                    <div className="relative w-auto my-6 mx-auto max-w-3xl">
                      {/*content*/}
                      <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        {/*header*/}
                        <div className="flex items-start justify-between p-3 border-b border-solid border-blueGray-200 rounded-t">
                          <h3 className="text-2xl font-semibold margin_botmopx">
                           Initial Payment Details
                          </h3>
                          <button
                            className="p-1 ml-auto bg-transparent border-0 text-black  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                            onClick={() => setShowModalInitial(false)}
                          >
                            <span className="bg-transparent btnAblsute text-black h-5 w-5 text-2xl block outline-none focus:outline-none">
                            &#x2715;
                            </span>
                          </button>
                        </div>
                        {/*body*/}
                        <div className="relative p-6 flex-auto">
                         <div className='PsitionReletive'>
                           <Elements stripe={stripePromise}>
                             <InitialPaymentGateway  partialPaymentsdata={partialPaymentsdata} />
                           </Elements>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
              ) : null}

        </div>
      </div> </> : <> <div>
        <h1 className='py-3 font-weight-bold h4 text-center'>Payment</h1>
         <div className='DetailsChanges'>
             
             <div className='d-flex brder relative'>
                 <div className='textCnme'> 
                   Name : 
                 </div>
                 <div className='textCnme'> 
                    {paymentData?.name}
                 </div>
             </div>

             <div className='d-flex brder relative'>
                 <div className='textCnme'> 
                   Email : 
                 </div>
                 <div className='textCnme'> 
                   {paymentData?.email}
                 </div>
             </div>

             <div className='d-flex brder relative'>
                 <div className='textCnme'> 
                    Plan Type : 
                 </div>
                 <div className='textCnme'> 
                   {paymentData?.userType}
                 </div>
             </div>

             <div className='d-flex brder relative'>
                 <div className='textCnme'> 
                   Amount : 
                 </div>
                 <div className='textCnme'> 
                   ${paymentData?.amount ?? 0}
                 </div>
             </div>

             <div className='d-flex brder relative'>
                 <div className='textCnme'> 
                   Payment Status : 
                 </div>
                 <div className='textCnme'> 
                   {paymentData?.paymentStatus}
                 </div>
             </div>

             <div className='d-flex brder relative'>
                 <div className='textCnme'> 
                   Address : 
                 </div>
                 <div className='textCnme'> 
                   {paymentData?.address}
                 </div>
             </div>

             <div className='PayNowBtn'>

              {paymentData?.paymentStatus == 'Successful' ? <> <p 
                  className='text-center  wdthshw   cursor-not-allowed py-2 mt-4 rounded-lg bg-green-500 text-white'
                >
                  Paid
              </p> </> : <>  <button type="button"
                  onClick={() => setShowModal(true)}
                  className='text-center  wdthshw  py-2 mt-4 rounded-lg bg-indigo-500 text-white'
                >
                  Pay Now
              </button> </>  }

             </div>

            {showModal ? (
                <>
                  <div
                    className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                  >
                    <div className="relative w-auto my-6 mx-auto max-w-3xl">
                      {/*content*/}
                      <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        {/*header*/}
                        <div className="flex items-start justify-between p-3 border-b border-solid border-blueGray-200 rounded-t">
                          <h3 className="text-2xl font-semibold margin_botmopx">
                            Payment Details
                          </h3>
                          <button
                            className="p-1 ml-auto bg-transparent border-0 text-black  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                            onClick={() => setShowModal(false)}
                          >
                            <span className="bg-transparent btnAblsute text-black h-5 w-5 text-2xl block outline-none focus:outline-none">
                            &#x2715;
                            </span>
                          </button>
                        </div>
                        {/*body*/}
                        <div className="relative p-6 flex-auto">
                         <div className='PsitionReletive'>
                           <Elements stripe={stripePromise}>
                             <PayMentGateway  paymentData={paymentData} />
                           </Elements>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
              ) : null}

        </div>
      </div> </>}

      </>
      )}

    </div>
  );



  const Welcome = (
    <div className='d-md-flex justify-content-center'>
      <div className=' max-w-md widthIncrgeDta w-100 w-md-100 h-md-0 p-2 pt-md-16'>
       <div className='d-block text-center'> 
         <div className='BckBtnWelocome' onClick={WelcomeBackBtn}> <img src={leftImg} alt='Back' /> Back To Home </div> 
        <h1 className='pt-6 fw-bold fs-large '>Welcome!</h1>
        <p className='text-sm '>Please enter the following information below.</p>
      </div>

        {inputList?.map((input, i) => {
          if (input.name !== 'address') {
            return (
              <Input
                key={i}
                name={input.name}
                type={input.type}
                label={input.label}
                changeHandler={handleInputChange}
                placeholder={input.placeholder}
                className={input.className}
              />
            );
          }
          else {
            return (
              <PlaceComponent values={values} setValues={setValues} handleInputChange={handleInputChange}/>
            )
          }
        })}

        <div className='flex justify-center PrntDivShn'>
          <button
            onClick={welcomeInputHandler}
            className='text-center  wdthshw  py-2 mt-4 rounded-lg bg-indigo-500 text-white'
          >
            Continue
          </button>
        </div>

      </div>
    </div>
  );

  const PdfPreview = ({ sourceUrl }) => {
    let embeddedUrl;
    try {
      if (sourceUrl.includes('drive.google.com')) {
        const fileId = sourceUrl.match(/\/d\/([^/]+)/)[1];
        embeddedUrl = `https://drive.google.com/file/d/${fileId}/preview`;
      } else if (sourceUrl.includes('onedrive.live.com')) {
        // OneDrive
        const embedCode = sourceUrl.match(/resid=([^&]+)/);
        if (embedCode) {
          embeddedUrl = `https://onedrive.live.com/embed?resid=${embedCode[1]}&authkey=!AEtacz-R5Nzx9Lk&em=2`;
        } else {
          throw new Error('Invalid OneDrive URL');
        }
      } else if (sourceUrl.includes('dropbox.com')) {
        // Dropbox
        const dlMatch = sourceUrl.match(/\/s\/([^?]+)/);
        embeddedUrl = `https://www.dropbox.com/s/${dlMatch[1]}/?raw=1`;
      } else {
        // Regular PDF URL
        embeddedUrl = sourceUrl;
      }
    } catch (error) {
      console.error('Error in PdfPreview component:', error.message);
      return <p>Error: Unable to preview PDF.</p>;
    }
  
    return (
      <div className="pdf-preview">
        <iframe
          src={embeddedUrl}
          title="PDF Preview"
          width="100%"
          height="600"
          frameBorder="0"
        ></iframe>
      </div>
    );
  };

  const Projectinformation  = (
    <>
    {isLoading ? (
                <div className='LodingLoader'>
                    <ColorRing
                      visible={true}
                      height="140"
                      width="140"
                      ariaLabel="blocks-loading"
                      wrapperStyle={{}}
                      wrapperClass="blocks-wrapper"
                      colors={['#6366f1', '#6366f1', '#6366f1', '#6366f1', '#6366f1']}
                    />
                </div>
            ) : (
             <></>

            )}
    <div className='d-md-flex justify-content-center p-2'>
      <div className='  max-w-md widthIncrgeDta w-100 w-md-100 h-md-0 p-2 pt-md-16'>
      <div className='BckBtnEnterProject' onClick={WelcomeBackBtnProject}> <img src={leftImg} alt='Back' /> Back To Home </div>
        <h1 className=' font-bold text-l relative'>Enter Project Information 
         </h1>
        <div className='w-full pt-3 flex flex-col mb-3'>
          <label htmlFor='comments' className='d-block text-dark fs-small fw-bold mb-2'>Comments*</label>
          <textarea
            ref={textareaRef}
            className="form-control p-2 bg-white rounded border border-gray-300 shadow-sm focus-border-indigo focus-ring-indigo small-text"
            name='comments'
            placeholder='Enter Your Comments Here'
            // onChange={handleInputChangeComments}
            onInput={handleShowInputChangeOther}
            cols='30'
            rows='5'   
          ></textarea>
        </div>
        <div className='w-full'>
          <label
            htmlFor='Uploadfiles'
            className='d-block text-dark fs-small fw-bold mb-2'>
            Upload PDF*
          </label>
          <div className='mt-2'>
           <div className=' flex mb-2'> 
           <div className='form-check mr-4'>
              <input
                className='form-check-input'
                type='radio'
                onChange={OnchnagePdfShow}
                name='checkboxdta'
                id='uploadPdf'
                value={'pdfupload'}
                checked={isChecked === 'pdfupload'}
              />
              <label className='form-check-label' htmlFor='uploadPdf'>
                Upload PDF
              </label>
            </div>
          
            <div className="form-check">
              <input className="form-check-input" type="radio" value={'pasteurl'}  onChange={OnchnagePdfShow} checked={isChecked === 'pasteurl'}  name='checkboxdta' id="PasteYrUrl" />
              <label className="form-check-label" for="PasteYrUrl">
                Paste Your PDF URL
              </label>
            </div>
          </div>

          {isChecked === 'pdfupload'? 
            <div>
              <div className='w-full bg-blue-50 DsyNoneDye'>
              <input type="file" id='FileUpload'  multiple accept=".pdf" onChange={onFileChange} />
              <label for='FileUpload'> 
                  <div className={`border rounded-lg border-dashed border-blue-600 p-12 mb-4 bg-gray-200`}>
                    <div className='flex flex-col justify-center items-center'>
                      <span className='text-gray-400 text-sm mx-2'>
                        <span className='text-black  text-sm mx-2'></span>
                        Upload Multiple PDF
                      </span>
                    </div>
                  </div>
                </label>
              </div>
              <div className='FlexData'>
              {renderPdfPreviews()}
              </div>
            </div>:
                <div>
                  <div className={`w-full mb-3 `}>
                    <label className='d-block text-dark fs-small fw-bold mb-2'>Paste Your PDF URL</label>
                    <input
                      className="form-control p-2 bg-white rounded border border-gray-300 shadow-sm focus-border-indigo focus-ring-indigo small-text"
                      type={`text`}
                      name={'urlpaste'}
                      placeholder="Paste PDF URL Here"
                      value={pdfUrl}
                      onChange={handleInputChangePdf}
                    />
                  </div>
                  {previewUrl ? (
                    <PdfPreview sourceUrl={previewUrl} />
                  ) : (
                    <p>Please enter a valid PDF URL with https:// or http:// .</p>
                    )}
                </div>
      }

            {showPdfPopup && (
            <div className="pdf-popup">
              <div className="pdf-popup-content">
                <button className='removePdf' onClick={closePdfPopup}> <img src={closeImg} alt='Close' /> </button>
                <iframe src={popupPdfUrl} title="PDF Preview" width="100%" height="500px"></iframe>
              </div>
            </div>
          )}

           <div className='flex justify-center'> 
            <button
              onClick={submitHandler}
              className='text-center  py-2 mt-4 wdthshw  mx-auto rounded-lg bg-indigo-500 text-white'
            >
              Submit
            </button>
           </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );


  const options = {
    // passing the client secret obtained from the server
    clientSecret: 'sk_test_51OaK4THXr2zVmhMluRdT7o4qA5LZrVZmhukNCC2uMT22KF1FyFIpDhQejVQTucuW8h2vhU1jBF42tgFukrsYyXOe00Hoh5ypwt',
  };

  
  return (
    <div class='flex flex-col'>
      <Header screenChanger={screenChanger} />
      <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <main class='flex-grow'>
        <div className='app_id'>
        {payementid === null || payementid === '' || payementid === 'payment' ? (
            <>
              {screen === 1 && Usertype}
              {screen === 2 && Welcome}
              {screen === 3 && Projectinformation}
            </>
          ) : (
            screen === 1 ? (
              <>
                {PayementPage}
              </>
            ) : null // This handles the "else if" condition
          )}
        </div>


      </main>
      <Footer />
    </div>
  );
}
export default App;